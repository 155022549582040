<template>
    <v-dialog
        v-if="visible"
        v-model="visible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="600"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text class="wrapperFormModal">
                <v-card 
                    flat
                >
                    <v-card-text>

                        <v-row no-gutters>

                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Тема") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-textarea
                                    v-model="subject"
                                    rows="3"
                                    no-resize
                                    hide-details
                                    required
                                    outlined
                                    dense
                                >
                                </v-textarea>
                            </v-col>

                        </v-row>

                        <v-row no-gutters>
                            
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Длительность,_мин") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-text-field
                                    v-model="duration"
                                    hide-details
                                    required
                                    outlined
                                    dense
                                    type="number"
                                    :rules="integerNotNull"
                                    class="cust-inputnumber"
                                    min="0"
                                >
                                </v-text-field>
                            </v-col>

                        </v-row>

                        <v-row no-gutters>
                            
                            <v-col cols="12" sm="12" md="3">
                                <label class="f-label">{{ $t("Докладчик") }}</label>
                            </v-col>

                            <v-col cols="12" sm="12" md="9">
                                <v-select
                                    v-model="speaker"
                                    :items="membersFiltered"
                                    item-text="Name"
                                    item-value="WorkplaceId"
                                    return-object
                                    hide-details
                                    outlined
                                    dense
                                    append-icon="fas fa-chevron-down"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                >
                                    <template v-slot:prepend-item>
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-text-field 
                                                    :placeholder="$t('Поиск')"
                                                    class="mb-2 autocomplete-search"
                                                    hide-details
                                                    dense
                                                    clearable
                                                    autofocus
                                                    v-model="speakerSearch"
                                                >
                                                </v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </v-select>
                            </v-col>

                        </v-row>

                    </v-card-text>
                </v-card>

            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="onOk"
                    v-if="subject && (duration && duration > 0) && speaker"
                >
                    {{ $t("Ок") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="onCancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import sys from '@/services/system';
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "AddMeetingQuestionDlg",
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    data () {
        return {
            title: "Добавить_вопрос",
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            speakerSearch: "",
        }
    },
    asyncComputed: {
        membersFiltered: {
            async get () {
                let items = Array.from(this.members);

                if (this.speakerSearch)
                    return items.filter(i => sys.checkSearch(i.Name, this.speakerSearch) || i.WorkplaceId == this.speaker?.WorkplaceId);
                else
                    return items;
            },
            default: [],
            watch: ['speakerSearch']
        },
    },
    computed: {
        ...mapGetters('dialogs/addMeetingQuestion', { visible: 'isVisible', members: 'getMembers' }),
        ...mapGetters({
            integerNotNull: 'getIntegerNotNullRule'
        }),
        subject: {
            get: function() {
                return this.$store.getters['dialogs/addMeetingQuestion/getSubject'];
            },
            set: function(v) {
                this.$store.commit('dialogs/addMeetingQuestion/SET_SUBJECT', v);
            }
        },
        duration: {
            get: function() {
                return this.$store.getters['dialogs/addMeetingQuestion/getDuration'];
            },
            set: function(v) {
                this.$store.commit('dialogs/addMeetingQuestion/SET_DURATION', v ? parseInt(v) : v);
            }
        },
        speaker: {
            get: function() {
                let s = this.$store.getters['dialogs/addMeetingQuestion/getSpeaker'];

                if (!s)
                    return null;
                
                return { Name: s.SpeakerName, WorkplaceId: s.SpeakerWorkplaceId };
            },
            set: function(v) {
                this.$store.commit('dialogs/addMeetingQuestion/SET_SPEAKER', { SpeakerEmployeeId: v.EmployeeId, SpeakerWorkplaceId: v.WorkplaceId, SpeakerName: v.Name });
            }
        },
        selectSpeakerTooltip() {
            return this.$t("Выбор_докладчика");
        }
    },
    methods: {
        ...mapActions('dialogs/addMeetingQuestion', ['ok', 'cancel']),
        async onOk() {
            await this.ok();
        },
        async onCancel() {
            await this.cancel();
        }
    }
}
</script>